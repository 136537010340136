<template>
    <a-table
        :loading="loading"
        :columns="comColumns"
        :data-source="data"
        bordered
        size="small"
        :row-selection="{
            selectedRowKeys,
            onChange: onSelectChange,
            type: 'checkbox',
        }"
        :pagination="pagination"
        @change="paginationChange"
        :scroll="{ x: 1500 }"
    >
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
        </template>
        <template #title>
            <div class="operate-content">
                <a-space direction="horizontal" :size="8">
                    <a-button
                        type="danger"
                        v-perm="'btn:supplier-item-import-rollback'"
                        @click="handleRevoke"
                        :disabled="!Boolean(selectedRowKeys?.length)"
                    >
                        撤销录入
                    </a-button>
                </a-space>
            </div>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import {
    apiSupplierlMaterialList,
    apiSupplierlMaterialRevokeEnterRecordsByMaterialIds,
} from "@/api";
import { showMessage, confirmModal } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "作业单号",
        dataIndex: "importNo",
    },
    {
        title: "行号",
        dataIndex: "lineNo",
    },
    {
        title: "采购订单号",
        dataIndex: "purchaseOrderCode",
    },
    {
        title: "物料名称",
        dataIndex: "itemName",
    },
    {
        title: "规格型号",
        dataIndex: "itemSpec",
    },
    {
        title: "供应商",
        dataIndex: "supplier",
    },
    {
        title: "单位",
        dataIndex: "primaryUom",
    },
];

export default defineComponent({
    props: ["detailRow"],
    setup(props) {
        const state = reactive({
            loading: false,
            selectedRowKeys: [],
            selectedRows: [],
            comColumns: computed(() => {
                let _columns = [...columns];
                const sourceType = props?.detailRow?.sourceType;
                if (sourceType == "USE_BATCH_NO") {
                    // 批次
                    _columns = [
                        ..._columns,
                        {
                            title: "数量",
                            dataIndex: "count",
                        },
                        {
                            title: "批次号",
                            dataIndex: "batchNo",
                        },
                        {
                            title: "供应商物料批次条码",
                            dataIndex: "barCode",
                        },
                    ];
                } else if (sourceType == "USE_SERIAL_NO") {
                    //流水号
                    _columns = [
                        ..._columns,
                        {
                            title: "数量",
                            dataIndex: "count",
                        },
                        {
                            title: "条码前缀",
                            dataIndex: "barCodePrefix",
                        },
                        {
                            title: "流水号位数",
                            dataIndex: "barCodeNoLength",
                        },
                        {
                            title: "起始流水号",
                            dataIndex: "barCodeNoStart",
                        },
                        {
                            title: "条码后缀",
                            dataIndex: "barCodeSuffix",
                        },
                    ];
                } else if (sourceType == "USE_THEIR_NO") {
                    //单件
                    _columns = [
                        ..._columns,
                        {
                            title: "单件物料条码",
                            dataIndex: "barCode",
                        },
                    ];
                }
                return _columns;
            }),
            data: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 15,
                showSizeChanger: false,
            },
        });

        const onSelectChange = (selectedRowKeys, selectedRows) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        const handleRevoke = () => {
            confirmModal({
                type: "warning",
                content: "撤销后将清除本次录入数据包括条码信息，是否确认撤销？",
                onOk: async () => {
                    let res = await apiSupplierlMaterialRevokeEnterRecordsByMaterialIds({
                        operationId: props.detailRow?.id,
                        itemIds: state.selectedRows.map(item => item?.id),
                    });
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        state.pagination.current = 1;
                        getTabelData();
                        state.selectedRowKeys = [];
                        state.selectedRows = [];
                    }
                },
            });
        };

        const paginationChange = pagination => {
            state.pagination = pagination;
            state.selectedRowKeys = [];
            state.selectedRows = [];
            getTabelData();
        };

        const getTabelData = async () => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiSupplierlMaterialList({
                pageNum,
                pageSize,
                queryParam: {
                    importNo: props?.detailRow?.importNo,
                    sourceType: props?.detailRow?.sourceType,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                const { totalRecord, recordList } = res?.data;
                state.pagination.total = totalRecord;
                state.data = recordList.map((item, index) => ({
                    ...item,
                    key: index,
                }));
            }
        };

        watch(
            () => props.detailRow,
            newVal => {
                if (newVal !== null) {
                    getTabelData();
                }
            },
            {
                immediate: true,
            }
        );

        return {
            ...toRefs(state),
            onSelectChange,
            handleRevoke,
            paginationChange,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
</style>
