<template>
    <a-drawer
        v-model:visible="visible"
        title="物料信息录入记录详情"
        @cancel="handleCancel"
        :destroyOnClose="true"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane :key="1" tab="基础信息">
                <BaseInfo :data="detailRow" />
            </a-tab-pane>
            <a-tab-pane :key="2" tab="物料列表">
                <MaterialList :detailRow="detailRow" />
            </a-tab-pane>
        </a-tabs>
    </a-drawer>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import Select from "@/components/Select";
import { getStatusColor, formateMoment } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import { baseURL } from "@/utils/config";
import BaseInfo from "./baseInfo";
import MaterialList from "./materialList ";
export default defineComponent({
    props: ["detailRow"],
    emits: ["eventUpdateOk", "update:detailRow"],
    components: {
        Select,
        DownOutlined,
        BaseInfo,
        MaterialList,
    },
    setup(props, { emit }) {
        const state = reactive({
            activeKey: 1,
            visible: false,
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:detailRow", null);
        };

        watch(
            () => props.detailRow,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            formateMoment,
            getStatusColor,
            baseURL,
        };
    },
});
</script>

<style lang="less" scoped>
.report-process-desc {
    color: grey;
    > div {
        margin-top: 16px;
        display: flex;
        > div {
            flex: 1;
        }
    }
}
.members-box {
    display: flex;
    flex-wrap: wrap;
}
</style>
