<template>
    <a-descriptions :column="3">
        <a-descriptions-item label="作业单号">
            {{ data?.importNo }}
        </a-descriptions-item>
        <a-descriptions-item label="操作类型">
            {{ data?.sourceTypeZh }}
        </a-descriptions-item>
        <a-descriptions-item label="录入行数">
            {{ data?.importRowCount }}
        </a-descriptions-item>
        <a-descriptions-item label="操作描述">
            {{ data?.description }}
        </a-descriptions-item>
        <a-descriptions-item label="物料录入总数">
            {{ data?.itemImportCount }}
        </a-descriptions-item>
        <a-descriptions-item label="操作人">
            {{ data?.createBy }}
        </a-descriptions-item>
        <a-descriptions-item label="提交时间">
            {{ data?.createTime }}
        </a-descriptions-item>
    </a-descriptions>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        data: {
            default: null,
        },
    },
});
</script>
