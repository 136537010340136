<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="params.importNo" placeholder="请输入作业单号" />
            <Select
                v-model:value="params.importStatus"
                :options="importStatusOptions"
                labelKey="name"
                valueKey="code"
                placeholder="请选择录入状态"
            />
            <Select
                v-model:value="params.sourceType"
                :options="sourceTypeOptions"
                labelKey="name"
                valueKey="code"
                placeholder="请选择操作类型"
            />
            <span>录入日期：</span>
            <a-range-picker v-model:value="date" valueFormat="YYYY-MM-DD" @change="changeDate" />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import Select from "@/components/Select";
import { apiSupplierlMaterialEnterRecordsInitData } from "@/api";
export default defineComponent({
    props: ["eventLevelOptions"],
    emits: ["search"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            importStatusOptions: [],
            teamOptions: [],
            sourceTypeOptions: [],
            date: [],
            params: {
                importNo: undefined,
                importStatus: undefined,
                sourceType: undefined,
                createTimeFrom: "",
                createTimeTo: "",
            },
        });

        const reset = () => {
            state.params.importNo = undefined;
            state.params.importStatus = undefined;
            state.params.sourceType = undefined;
            state.params.createTimeFrom = "";
            state.params.createTimeTo = "";
            state.date = [];
            search();
        };

        const changeDate = (_, dateStrings) => {
            let [start, end] = dateStrings;
            state.params.createTimeFrom = start ? `${start} 00:00:00` : "";
            state.params.createTimeTo = end ? `${end} 23:59:59` : "";
        };

        const search = () => {
            state.params.importNo = state.params.importNo?.trim();
            emit("search", state.params);
        };

        search();

        const getIndexData = async () => {
            let res = await apiSupplierlMaterialEnterRecordsInitData();
            if (res.status === "SUCCESS") {
                state.importStatusOptions = res?.data?.importStatusCandidate;
                state.sourceTypeOptions = res?.data?.sourceTypeCandidate;
            }
        };
        getIndexData();

        return {
            ...toRefs(state),
            search,
            reset,
            changeDate,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>
