<template>
    <div class="container">
        <Detail v-model:detailRow="detailRow" />
        <FilterBlock @search="search" />
        <div class="table-content">
            <!-- <Table :columns="columns" :data="data"></Table> -->
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 2000 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'projectName'">
                        {{ record?.workOrderInfo?.projectName }}
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="detailRow = record">详情</a>
                            <a
                                v-perm="'btn:supplier-item-import-rollback'"
                                @click="handleRevoke(record.id)"
                            >
                                撤销录入
                            </a>
                        </a-space>
                    </template>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import Detail from "./detail";
import Table from "@/components/Table";
import { formateMoment, confirmModal, getStatusColor, showMessage } from "@/utils/common";
import {
    apiSupplierlMaterialEnterRecordsList,
    apiSupplierlMaterialRevokeEnterRecordsByOpreatId,
} from "@/api";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "作业单号",
        dataIndex: "importNo",
    },
    {
        title: "录入状态",
        dataIndex: "importStatusZh",
    },
    {
        title: "操作类型",
        dataIndex: "sourceTypeZh",
    },
    {
        title: "录入行数",
        dataIndex: "importRowCount",
    },
    {
        title: "操作描述",
        dataIndex: "description",
    },
    {
        title: "物料录入总数",
        dataIndex: "itemImportCount",
    },
    {
        title: "操作人",
        dataIndex: "createBy",
    },
    {
        title: "录入时间",
        dataIndex: "createTime",
    },
    {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        width: 150,
    },
];
export default defineComponent({
    components: {
        FilterBlock,
        Detail,
        Table,
    },
    setup() {
        const state = reactive({
            detailRow: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiSupplierlMaterialEnterRecordsList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const handleOpenDetail = id => {
            state.detailRow = id;
        };

        const handleRevoke = id => {
            confirmModal({
                type: "warning",
                content: "撤销后将清除本次录入数据包括条码信息，是否确认撤销？",
                onOk: async () => {
                    let res = await apiSupplierlMaterialRevokeEnterRecordsByOpreatId(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            handleOpenDetail,
            getTabelData,
            formateMoment,
            getStatusColor,
            handleRevoke,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
